<template>

  <v-app>

    <v-app-bar
      color="primary"
      app
      dark>

      <!--<img src="@/assets/images/modded-logo-circle.png" class="ml-n2 mr-4" width="40" />-->

      <!--<v-select-->
        <!--v-if="clientNames.length > 1 && $vuetify.breakpoint.smAndUp"-->
        <!--ref="selectComponent"-->
        <!--:items="clientNames"-->
        <!--item-text="text"-->
        <!--item-value="value"-->
        <!--menu-props="top"-->
        <!--v-model="actorId"-->
        <!--class="mt-5"-->
        <!--flat-->
        <!--dense-->
        <!--full-width-->
      <!--/>-->
      <!--<v-card else>-->
      <!--</v-card>-->

      <v-spacer></v-spacer>

      <v-btn icon link @click="signout()">
        <v-icon>mdi-logout</v-icon>
      </v-btn>

      <template v-slot:extension v-if="$vuetify.breakpoint.smAndUp">
        <v-tabs>
          <v-tab
            v-for="item in items"
            :key="item.title"
            link
            :to="{ name: item.name }"
          >
            {{ item.title }}
          </v-tab>
        </v-tabs>
      </template>

    </v-app-bar>

    <v-main>
      <router-view />
    </v-main>

    <v-bottom-navigation
      v-if="$vuetify.breakpoint.xsOnly"
      app
      grow
      dark
      background-color="primary"
      class="pa-0 ma-0"
      style="max-height: 100%;"
    >
      <v-btn
        v-for="item in items"
        :key="item.title"
        link
        :to="{name: item.name }"
        color="primary"
        >
        <span>{{ item.title }}</span>
        <v-icon>{{ item.icon }}</v-icon>
      </v-btn>
    </v-bottom-navigation>

  </v-app>

</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import Console from '@/console'

export default {

  data() {
    return {
      drawer: true,

      clientName: '',

      items: [],
    }
  },

  created() {
    this.items = this.menuItems
  },

  computed: {
    ...mapGetters('Actor', ['id']),
    ...mapGetters('Account', [ 'name', 'initials' ]),
    ...mapGetters([ 'canAccessApiDocs' ]),

    isMini() {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs': return true
        case 'sm': return true
        case 'md': return true
        case 'lg': return false
        case 'xl': return false
      }
      return false
    },

    employeeName() {
      return this.employeeInfo?.name ?? this.name
    },

    clientNames: {
      get() {
        return [{text: "hello", value: "no"}]

        //return this.clients.map(c => { return {
        //  text: c.client_name,
        //  value: c.actor_id
        //}})
      },
      set() { /* This is dumb */ }
    },

    actorId: {
      get() { return this.id },
      set (actorId) {
        this.setActorId(actorId)
        .then(() => this.loadEmployee())
        .catch(error => Console.error('Could not properly change client/employee', error))
      }
    },

    isInIframe: () => window.self !== window.top,

    menuItems() {
      let items = [
        { name: 'channels', title: 'Channels', icon: 'mdi-map-marker-path' },
        { name: 'applications', title: 'Applications', icon: 'mdi-map-marker-path' },
        { name: 'users', title: 'Users', icon: 'mdi-account-multiple' },
      ]

      if (this.canAccessApiDocs) {
        items.push({ name: 'apidocs', title: 'API Docs', icon: 'mdi-code-braces' })
      }

      //if (this.canManageContacts) {
      //  items.push({ name: 'contacts', title: 'Contacts', icon: 'mdi-account-cog-outline' })
      //}

      return items
    },

  },

  methods: {
    ...mapActions('Auth', ['setActorId', 'signOut']),
    ...mapActions('Employee', ['loadEmployee', 'getEmployee', 'getBenefitPlans', 'getPaycard']),

    async signout() {
      await this.signOut()
      return this.$router.push( { name: 'sign-in' } )
    }
  },

  watch: {
    //clients: {
    //  handler() {
    //    const clients = this.clients

    //    if (!clients) return

    //    this.clientNames = this.clients.map(c => c.client_name)

    //    if (! this.clientName && this.clientNames.length > 0) {
    //      this.clientName = this.clientNames?.[0]
    //    }
    //  }
    //},

  }
}
</script>

<style scoped>

.v-overflow-btn {
  font-size: 0.8em;
}

.initials-text {
  font-size: 1.5em;
  font-weight: bold;
}
.v-overflow-btn {
  font-size: 1.2em;
  font-weight: bold;
}
>>>.v-select > .v-input__control .v-input__slot:before {
  border-width: 0;
}
>>>.v-select > .v-input__control .v-input__slot:after {
  border-width: 0;
}

.v-item-group.v-bottom-navigation .v-btn.v-size--default {
  height: inherit;
}

.v-bottom-navigation {
  height: auto !important;
}

.v-bottom-navigation >>> .v-btn__content {
  padding-top: 10px;
  padding-bottom: 10px;
  margin-bottom: constant( safe-area-inset-bottom ); /* move footer up on iPhone X - iOS 11.0 */
  margin-bottom: env( safe-area-inset-bottom ); /* move footer up on iPhone X - iOS 11.2 */
}
</style>

